import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HashTags from "../components/hashtags"
import NavLink from "../components/navlink"

export default function FeaturedPartners() {
  let keywords = [
    "theplatininumservices",
    "dmc Italy",
    "hotels",
    "genoa",
    "rome",
    "roma",
    "comolake",
    "como",
    "moltrasio",
    "alagna",
    "monterosa",
    "salento",
    "puglia",
    "apulia",
    "siracusa",
    "syracuse",
    "tuscany",
    "golfcourse",
    "skiresort",
    "mirahotels",
    "miraresorts",
    "fivestarhotels",
    "fourstarhotels",
    "private jet",
    "airtravel",
    "radissonbluGHR",
    "radissonblu",
    "grandhotelimperiale",
    "bristol palace hotel",
    "4L collection",
    "thehoxtonrome",
    "luxury",
    "logistics",
    "hotels",
    "resort properties",
    "boutique hotels",
    "uniquememories",
    "unforgettableexperiences",
    "anantara",
    "#preferred",
    "#lungarno collection",
    "florence",
    "firenze",
    "europe",
    "worldwide"
  ]

  let imgWidth = 140
  let iLeft = 1
  let cLeft = `overflow-hidden xl:my-4 w-${iLeft.toString()}/12`
  let cRight = `overflow-hidden xl:my-4 xl:p-1 w-${(12 / 2 - iLeft).toString()}/12`


  return (
    <Layout location="/partners" title="Partners">
      <Seo title="Partners" />
      <h1 className="text-center text-3xl mb-8">
        The Platinum Services | Dmc Italy{" "}
      </h1>
      <h2 className="text-center text-2xl mb-14">
        Hotel Partners | Italy | Europe
      </h2>

      <p className="text-xl mb-8 text-center">
        The staff of The Platinum Services Dmc Italy and Events, throughout the
        years, has carefully selected and personally visited a number of hotels,
        including luxury, boutique, and resort properties.
        <br />
        Every year, we go through them again to make sure you're comfortable and
        safe.
      </p>

      <div className="flex flex-wrap overflow-hidden">

        {/**/}
        <div className={cLeft}>
          <StaticImage
            src={"../images/partners/accor.jpg"}
            alt="Logo Bristol"
            width={imgWidth}
          />
        </div>
        <div className={cRight}>
          <h3 className="font-bold">Accor</h3>
          Accor offers the most diverse brand portfolio of the industry with 40+ hotel brands across all segments.The hospitality ecosystem also encompasses entertainment, restaurants and bars, co-working and business services and solutions to boost performance.
        </div>

        {/**/}
        <div className={cLeft}>
          <StaticImage
            src={"../images/partners/bristol.png"}
            alt="Logo Bristol"
            width={imgWidth}
          />
        </div>
        <div className={cRight}>
          <h3 className="font-bold">Bristol Palace Hotel Genoa</h3>
          The Hotel Bristol Palace has been added to the prestigious Luxury
          Collection Due Torri Hotels and has been refurbished to give its
          guests the Liberty elegance of this well-known place in Genoa's
          center, where the city's past and the distant fragrance of the sea
          combine into one ‘one.
        </div>

        {/**/}
        <div className={cLeft}>
          <StaticImage
            src={"../images/partners/feelings.png"}
            alt="Logo 4L Collection"
            width={imgWidth}
          />
        </div>
        <div className={cRight}>
          <h3 className="font-bold">4L Collection Rome</h3>
          An intimate, human touch, caring for guests and offering empathy,
          style, and the feel-good factor, ensuring a "safe" and "quality stay"
          and, most importantly, the pleasure of returning in Rome. The 4L
          Collection Rome is the result of a family tradition that has developed
          over time without losing its distinguishing feature: people who make a
          difference.
        </div>

        {/**/}
        <div className={cLeft}>
          <StaticImage
            src={"../images/partners/imperiale.png"}
            alt="Logo Grand Hotel Imperiale Como Lake"
            width={imgWidth}
          />
        </div>
        <div className={cRight}>
          <h3 className="font-bold">Grand Hotel Imperiale Como Lake</h3>
          The allure of a grand historic mansion with a view of the lake, as
          well as the beauty of a world-famous scenery: Como Lake. A favorite
          haunt of poets and authors who have long celebrated peace and
          tranquility.
        </div>

        {/**/}
        <div className={cLeft}>
          <StaticImage
            src={"../images/partners/mira.png"}
            alt="Logo Mira Hotels &amp; Resorts Italy"
            width={imgWidth}
          />
        </div>
        <div className={cRight}>
          <h3 className="font-bold">Mira Hotels &amp; Resorts Italy</h3>
          MIRA Hotels &amp; Resorts portfolio consists of four and five-star
          hotels situated in the most beautiful Italian regions. The aim is to
          offer a relaxing hospitality experience, where guests can feel safe
          and free to enjoy the nature and beauty of the area with a great
          choice of activities, unique memories, friendships and unforgettable
          experiences.
        </div>

        {/**/}
        <div className={cLeft}>
          <StaticImage
            src={"../images/partners/radissonblu.jpg"}
            alt="Logo Radisson Blu Ghr Hotel Rome"
            width={imgWidth}
          />
        </div>
        <div className={cRight}>
          <h3 className="font-bold">Radisson Blu Ghr Hotel Rome</h3>
          In the heart of the exclusive and elegant Parioli district, among
          prestigious museums, parks, villas, you will find Radisson Blu GHR
          Hotel, an historic hotel in the eternal city that has been
          protagonists of Roman hotellerie for sixty years which today
          introduces itself with its completely renewed 285 rooms and
          affiliation with the prestigious international brand.
        </div>

        {/**/}
        <div className={cLeft}>
          <StaticImage
            src={"../images/partners/hoxton.png"}
            alt="Logo Hoxton"
            width={imgWidth}
          />
        </div>
        <div className={cRight}>
          <h3 className="font-bold">The Hoxton Rome:</h3>
          The idea is to immerse guests in a mid-century Italian-style
          atmosphere, providing an experience that takes them on a journey
          through the nostalgic notes of some beloved film classics. Murano
          chandeliers, herringbone floors, and custom-made antique furniture
          combine to create a wonderful Rome escape.
        </div>

        {/**/}
        <div className={cLeft}>
          <StaticImage
            src={"../images/partners/anantara.png"}
            alt="Anantara Palazzo Naiadi Roma"
            width={imgWidth}
          />
        </div>
        <div className={cRight}>
          <h3 className="font-bold">Anantara Palazzo Naiadi Roma</h3>
          In a crescent-shaped palace fronting the Piazza della Repubblica, immerse yourself in the glory of old Rome. 
          With 19th-century marble architecture and meeting spaces hung over the remains of Diocletian's Baths, Anantara Palazzo Naiadi has a unique heritage.
        </div>

        {/**/}
        <div className={cLeft}>
          <StaticImage
            src={"../images/partners/lux.png"}
            alt="Preferred Hotels & Resorts"
            width={imgWidth}
          />
        </div>
        <div className={cRight}>
          <h3 className="font-bold">Preferred Hotels & Resorts</h3>
          Preferred Hotels & Resorts represents the finest and most diverse global portfolio of independent hotels and independent hotel experiences. 
          Five distinctive collections allow one to craft their own inspirations as they travel the world in search of memories and new opportunities. 
          To help ensure the highest levels of customer satisfaction, Preferred Hotels & Resorts requires that each of its member hotels conform to the 
          Preferred Standards of Excellence™, renowned quality standards that are measured by yearly anonymous on-site inspections 
          carried out by professional third-party experts.
        </div>

        {/**/}
        <div className={cLeft}>
          <StaticImage
            src={"../images/partners/lungarno.png"}
            alt="Lungarno Collection Florence"
            width={imgWidth}
          />
        </div>
        <div className={cRight}>
          <h3 className="font-bold">Lungarno Collection Florence</h3>
          The age-old art of hospitality should follow precise rules in which the most important figure is the guest and his or her needs and feelings. 
          Our hotels in Florence follow this principle in offering themselves to the visitor who comes to stay in the historic “Cradle of the Renaissance. 
          ”This is the spirit that has driven  to create  4- and 5-star luxury hotels, all located near Ponte Vecchio and a stone’s throw from the Uffizi Gallery, 
          the Duomo, Piazza della Signoria and Palazzo Pitti, in a perfect position to be captivated by the magnificence of the art and architecture of Florence.
        </div>

      </div>

      <HashTags
        key="hashtags"
        list={keywords}
        mainCss="my-1 py-6 px-1 w-full overflow-hidden text-right"
        itemCss="inline-block px-1 text-xs font-normal text-gray-400 mr-2 mb-2"
      />
    </Layout>
  )
}

/*


        <div className={cLeft}>foto</div>
        <div className={cRight}>testo</div>



        

          <div className={colPartner}>
            <div className={colLeft}>
            immagine
            </div>

            <div className={colRight}>
              <h3 className="font-bold">titolo</h3>
              Testo
            </div>
          </div>



*/
